<template>
  <div class="modal">
    <div class="modal-right">
      <button class="modal-close">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.1894 6L6.35156 18"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M6.35156 6L18.1894 18"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </button>
      <h1 class="title">Регистрация</h1>
      <div class="mobile slider-block">
        <div class="block_bonus">
          <div class="bonus_baner">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0c5.517 0 10 4.483 10 10s-4.483 10-10 10S0 15.517 0 10 4.483 0 10 0Zm0 8.889A1.111 1.111 0 0 0 8.889 10v4.444a1.111 1.111 0 0 0 2.222 0V10A1.111 1.111 0 0 0 10 8.889Zm0-3.333a1.111 1.111 0 1 0 0 2.222 1.111 1.111 0 0 0 0-2.222Z"
              ></path>
            </svg>
          </div>
          <div class="bonus_item__info">
            <h4>На 1-й депозит - 50% + 50 FS</h4>
            <label class="switch">
              <input type="checkbox" checked />
              <span class="slider"></span>
            </label>
          </div>
        </div>
        <div class="arrow_slider arrow_slider_left">
          <svg
            width="10"
            height="8"
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.77466e-08 1.32109C7.26125e-08 1.66118 0.121736 2.00769 0.377386 2.26437L4.28762 6.26723C4.68013 6.66904 5.3265 6.66883 5.71875 6.26676L9.62337 2.26437C10.1225 1.75102 10.1286 0.9104 9.62337 0.390634C9.13033 -0.122716 8.3147 -0.129133 7.81557 0.390634L4.99733 3.2718L2.18518 0.384211C1.67997 -0.129138 0.870419 -0.129132 0.371293 0.390634C0.127816 0.647309 4.31611e-08 0.987411 5.77466e-08 1.32109Z"
            ></path>
          </svg>
        </div>
        <div class="arrow_slider arrow_slider_right">
          <svg
            width="10"
            height="8"
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.77466e-08 1.32109C7.26125e-08 1.66118 0.121736 2.00769 0.377386 2.26437L4.28762 6.26723C4.68013 6.66904 5.3265 6.66883 5.71875 6.26676L9.62337 2.26437C10.1225 1.75102 10.1286 0.9104 9.62337 0.390634C9.13033 -0.122716 8.3147 -0.129133 7.81557 0.390634L4.99733 3.2718L2.18518 0.384211C1.67997 -0.129138 0.870419 -0.129132 0.371293 0.390634C0.127816 0.647309 4.31611e-08 0.987411 5.77466e-08 1.32109Z"
            ></path>
          </svg>
        </div>
      </div>
      <form
        action="#"
        id="registrationForm"
        :class="{'form_disabled': isRequestSending}"
        @submit.prevent="submitForm"
      >
        <div class="form-row">
          <select id="telCode" v-model="telCode">
            <option value="+7" selected>+7</option>
            <option value="+380">+380</option>
          </select>
          <input type="tel" id="phone" placeholder="Номер телефона" required v-model="phone" />
        </div>
        <div class="form-group">
          <input type="password" id="password" placeholder="Введите пароль" required v-model="password" />
          <span class="showpassword">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="svg-716e1d">
                  <path
                    id="svg-12d1a8"
                    d="M2 12C2 12 5 5 12 5C19 5 22 12 22 12C22 12 19 19 12 19C5 19 2 12 2 12Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    id="svg-e962c"
                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </span>
        </div>
        <div class="form-row">
          <select id="country" v-model="country">
            <option value="RU" selected>Россия</option>
            <option value="UA">Украина</option>
          </select>
          <select id="currency" v-model="currency">
            <option value="RUB" selected>RUB</option>
            <option value="UAH">UAH</option>
          </select>
        </div>
        <button type="button" class="div_text" id="checkCode">
          <p><b>Есть код для баланса</b></p>
        </button>
        <div class="form-group codeBlock">
          <input type="text"  id="promo" v-model="promocode" />
        </div>
        <div class="checkbox-group">
          <input type="checkbox" checked id="checkbox" />
          <label for="checkbox"
          >Я согласен с <a href="#">Условиями и приложениями</a> и
            подтверждаю, что мне исполнилось 18 лет</label
          >
        </div>
        <input
          type="submit"
          class="btn-register"
          id="registr"
          value="Регистрация"
        />
      </form>
      <div class="sign-in">
        <p>Есть аккаунт?</p>
        <b>Войти</b>
      </div>
    </div>
    <div class="modal-left">
      <div class="block_bonus">
        <div class="bonus_baner">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0c5.517 0 10 4.483 10 10s-4.483 10-10 10S0 15.517 0 10 4.483 0 10 0Zm0 8.889A1.111 1.111 0 0 0 8.889 10v4.444a1.111 1.111 0 0 0 2.222 0V10A1.111 1.111 0 0 0 10 8.889Zm0-3.333a1.111 1.111 0 1 0 0 2.222 1.111 1.111 0 0 0 0-2.222Z"
            ></path>
          </svg>
        </div>
        <div class="bonus_item__info">
          <h4>На 1-й депозит - 50% + 50 FS</h4>
          <label class="switch">
            <input type="checkbox" checked />
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div class="block_bonus">
        <div class="bonus_baner bonus_baner2">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0c5.517 0 10 4.483 10 10s-4.483 10-10 10S0 15.517 0 10 4.483 0 10 0Zm0 8.889A1.111 1.111 0 0 0 8.889 10v4.444a1.111 1.111 0 0 0 2.222 0V10A1.111 1.111 0 0 0 10 8.889Zm0-3.333a1.111 1.111 0 1 0 0 2.222 1.111 1.111 0 0 0 0-2.222Z"
            ></path>
          </svg>
        </div>
        <div class="bonus_item__info">
          <h4>На 1-й депозит - 75% + 75 FS</h4>
          <label class="switch">
            <input type="checkbox" />
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div class="block_bonus">
        <div class="bonus_baner bonus_baner3">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0c5.517 0 10 4.483 10 10s-4.483 10-10 10S0 15.517 0 10 4.483 0 10 0Zm0 8.889A1.111 1.111 0 0 0 8.889 10v4.444a1.111 1.111 0 0 0 2.222 0V10A1.111 1.111 0 0 0 10 8.889Zm0-3.333a1.111 1.111 0 1 0 0 2.222 1.111 1.111 0 0 0 0-2.222Z"
            ></path>
          </svg>
        </div>
        <div class="bonus_item__info">
          <h4>На 1-й депозит - 100% + 100 FS</h4>
          <label class="switch">
            <input type="checkbox" />
            <span class="slider"></span>
          </label>
        </div>
      </div>

      <!---->
      <!---->
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      telCode: '+7',
      phone: '',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'SKUF',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.telCode}${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
